import React, {FC} from "react"
import {EnvironmentDtoDetail} from "models/Environment"
import {StyledCard} from "components/StyledCard"

interface Props {
  environment: EnvironmentDtoDetail,
}

const EnvironmentInfo: FC<Props> = ({environment}) => {
  return <StyledCard title={<div>Environnement - {environment.code}</div>}>
    {/*<Descriptions title="Sequence Info" bordered={true}>*/}
    {/*<Descriptions.Item label="Id">{environment.id}</Descriptions.Item>*/}
    {/*<Descriptions.Item label="Code">{environment.code}</Descriptions.Item>*/}
    {/*</Descriptions>*/}
  </StyledCard>
}


export default EnvironmentInfo