import React, {FC} from "react"
import {SequenceDtoDetail} from "models/Sequence"
import VersionInfo from "components/VersionInfo"
import {StyledCard} from "components/StyledCard"

interface Props {
  sequence: SequenceDtoDetail,
}

const SequenceInfo: FC<Props> = ({sequence}) => {
  return <StyledCard title={<div>Sequence - {sequence.code}</div>}>
    {/*  <Descriptions title="Sequence Info" bordered={true}>*/}
    {/*  <Descriptions.Item label="Id">{sequence.id}</Descriptions.Item>*/}
    {/*  <Descriptions.Item label="Code">{sequence.code}</Descriptions.Item>*/}
    {/*</Descriptions>*/}
    {sequence.versions.map((elem, i) => <VersionInfo version={elem} key={i}/>)}
  </StyledCard>
}


export default SequenceInfo