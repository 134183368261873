import Api from "services/api"
import {IamUserDtoSummary, ServiceAccountDtoDetail} from "models/User"

export const listUsers = async (): Promise<IamUserDtoSummary[]> => Api.get<void, IamUserDtoSummary[]>(`/user`, {
  data: {
    failForbiddenSilently: true,
  },
})

export const listServiceAccounts = async (): Promise<ServiceAccountDtoDetail[]> => Api.get<void, ServiceAccountDtoDetail[]>(`/serviceAccount`, {
  data: {
    failForbiddenSilently: true,
  },
})
