import React, {FC, useCallback, useEffect} from "react"
import {Button, Form, Input, Select} from 'antd';
import useDispatch from "hooks/useDispatch"

interface Props {
  customerAbbreviation: string
}

interface FormValues {
  subjectId: string,
  name: string,
  description: string,
  allowedCIDRs: Set<string>;
}

const ServiceAccountCreator: FC<Props> = ({customerAbbreviation}) => {
  const [form] = Form.useForm<FormValues>();
  const dispatch = useDispatch()

  useEffect(() => {
    form.resetFields()
  }, [customerAbbreviation, form]);

  const onFinish = useCallback((values: FormValues) => {
    dispatch.definition.createServiceAccount({
        customerAbbrev: customerAbbreviation,
        dto: {
          username: values.name,
          description: values.description ?? "",
          allowedCidr: [...values.allowedCIDRs],
        },
      })
      .then(() => form.resetFields())
      .catch(() => {
      })
  }, [customerAbbreviation, dispatch.definition, form])

  return (
    <Form
      layout={'vertical'}
      form={form}
      initialValues={{
        allowedCIDRs: [],
      }}
      onFinish={onFinish}
    >
      <Form.Item label="Name" name={"name"} rules={[{required: true}]}>
        <Input/>
      </Form.Item>
      <Form.Item label="Description" name={"description"}>
        <Input/>
      </Form.Item>
      <Form.Item label="Allowed CIDRs" name={"allowedCIDRs"}>
        <Select mode={"tags"} allowClear={true} data-lpignore="true"/>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType={"submit"}>Create</Button>
      </Form.Item>
    </Form>
  );
}


export default ServiceAccountCreator