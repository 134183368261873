import React, {FC, useCallback, useMemo} from "react"
import {useSelector} from "react-redux"
import {getRelationships} from "redux/selectors/definition"
import {Button, Popconfirm, Table} from "antd"
import {ColumnsType} from "antd/es/table";
import {ObjectReferenceDto} from "models/Definition"
import useDispatch from "hooks/useDispatch"
import {Link} from "react-router-dom"

interface Props {
  resource: ObjectReferenceDto
}

interface DataRow {
  relation: string,
  subjectType: string,
  subjectId: string,
  subjectDisplayName: string,
}

const RelationshipList: FC<Props> = ({resource}) => {
  const relationships = useSelector(getRelationships)
  const uniqueRelations = useMemo(() => new Set(relationships?.map(elem => elem.relation) ?? []), [relationships])
  const uniqueSubjectType = useMemo(() => new Set(relationships?.map(elem => elem.subject.type) ?? []), [relationships])

  const dispatch = useDispatch()

  const onDelete = useCallback((row: DataRow) => {
    dispatch.definition.deleteRelationship({
      resource,
      relation: row.relation,
      subject: {
        type: row.subjectType,
        id: row.subjectId,
      },
    })
  }, [dispatch.definition, resource])

  const columns: ColumnsType<DataRow> = [
    {
      title: "Relation",
      dataIndex: "relation",
      key: 'relation',
      filters: [...uniqueRelations].map((relation => ({
        text: relation,
        value: relation,
      }))),
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record: DataRow) => record.relation === String(value),
      sorter: (a, b) => a.relation.localeCompare(b.relation),
    },
    {
      title: "Subject Type",
      dataIndex: "subjectType",
      key: 'subjectType',
      filters: [...uniqueSubjectType].map((subjectType => ({
        text: subjectType,
        value: subjectType,
      }))),
      filterMode: "tree",
      filterSearch: true,
      sorter: (a, b) => a.subjectType.localeCompare(b.subjectType),
      render: (value, record) => <Link to={`/app/full-admin/resource?name=${record.subjectType}&id=${record.subjectId}`}>
        <Button type={"link"}>
          {value}
        </Button>
      </Link>,
    },
    {
      title: "Subject Id",
      dataIndex: "subjectId",
      key: 'subjectId',
      sorter: (a, b) => a.subjectId.localeCompare(b.subjectId),
      render: (value, record) => <Link to={`/app/full-admin/resource?name=${record.subjectType}&id=${record.subjectId}`}>
        <Button type={"link"}>
          {value}
        </Button>
      </Link>,
    },
    {
      title: "Subject Display Name",
      dataIndex: "subjectDisplayName",
      key: 'subjectDisplayName',
      sorter: (a, b) => a.subjectDisplayName.localeCompare(b.subjectDisplayName),
    },
    {
      title: 'Actions',
      key: 'action',
      render: (a, record, i) => (
        <Popconfirm
          title="Relationship deletion"
          description="Are you sure to delete this relationship ?"
          onConfirm={() => onDelete(record)}
          okText="Yes"
          cancelText="No"
        >
          <Button type={"primary"} danger key={i}>Delete</Button>
        </Popconfirm>
      ),
    },
  ]

  const dataSource = useMemo(() => {
    return relationships?.map((elem) => ({
      relation: elem.relation,
      subjectType: elem.subject.type,
      subjectId: elem.subject.id,
      subjectDisplayName: elem.subject.displayName ?? "",
      key: `${elem.subject.type}${elem.subject.id}${elem.resource.type}${elem.resource.id}${elem.relation}`,
    })) ?? []
  }, [relationships])

  return <Table size={"small"}
                dataSource={dataSource}
                columns={columns}
                pagination={{
                  defaultPageSize: 15,
                }}/>;
}


export default RelationshipList