import React, {FC, useMemo} from "react"
import {useSelector} from "react-redux"
import {getPermissions} from "redux/selectors/definition"
import {Button, Table} from "antd"
import {ColumnsType} from "antd/es/table";
import {Link} from "react-router-dom"

interface DataRow {
  permission: string,
  username: string,
  userId: string,
  path: "user" | "service_account"
}

const Permissions: FC = () => {
  const permissions = useSelector(getPermissions)

  const usernames = useMemo(() => [...new Set(Object.values(permissions)
    .flatMap(elem => elem.users.map(user => user.email).concat(elem.serviceAccounts.map(user => user.username))))], [permissions])

  const columns: ColumnsType<DataRow> = [{
    title: "Permission",
    dataIndex: "permission",
    key: 'permission',
    filters: Object.keys(permissions).map((perm => ({
      text: perm,
      value: perm,
    }))),
    filterMode: "tree",
    filterSearch: true,
    onFilter: (value, record: DataRow) => record.permission === String(value),
    sorter: (a, b) => a.permission.localeCompare(b.permission),
  }, {
    title: "Username",
    dataIndex: "username",
    key: 'username',
    sorter: (a, b) => a.username.localeCompare(b.username),
    filters: usernames.map((user => ({
      text: user,
      value: user,
    }))),
    filterMode: "tree",
    filterSearch: true,
    onFilter: (value, record: DataRow) => record.username === String(value),
    render: (value, record) => <Link to={`/app/full-admin/${record.path}?id=${record.userId}`}>
      <Button type={"link"}>
        {value}
      </Button>
    </Link>,
  }]

  const dataSource: DataRow[] = useMemo(() =>
    Object.entries(permissions)?.flatMap(([permission, accounts]): DataRow[] => accounts.users.map((user) => ({
        permission,
        username: user.email,
        userId: user.id,
        path: "user",
        key: `${user.id}-${permission}`,
      })).concat(accounts.serviceAccounts.map((serviceAccount) => ({
        permission,
        username: serviceAccount.username,
        userId: String(serviceAccount.id),
        path: "service_account",
        key: `${serviceAccount.id}-${permission}`,
      }))) as DataRow[],
    ), [permissions])

  return <Table size={"small"}
                dataSource={dataSource}
                columns={columns}
                pagination={{
                  defaultPageSize: 15,
                }}/>;
}


export default Permissions