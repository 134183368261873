import {Models} from "@rematch/core"
import environment from "redux/models/environment"
import definition from "redux/models/definition"

export default interface RootModel extends Models<RootModel> {
  environment: typeof environment
  definition: typeof definition
}

export const models: RootModel = {environment, definition}
