import {VersionDtoSummary} from "models/Version"

export enum SourceDatabaseType {
  TEST = "TEST",
  STALE = "STALE",
  STATIC = "STATIC",
  LIVE = "LIVE",
  EXPLORATION = "EXPLORATION"
}

export interface SourceDatabaseDtoDetail {
  id: number,
  code: string,
  name: string,
  type: SourceDatabaseType,
  toBackup: boolean
}

export interface SourceDatabaseDtoCreateOrUpdate {
  type: string
  name: string
  toBackup: boolean
}

export interface SourceDatabaseDtoDetailWithParents {
  id: number;
  code: string;
  name: string;
  type: SourceDatabaseType;
  version: VersionDtoSummary;
  toBackup: boolean
}