import React, {FC, useEffect} from "react"
import useCustomSearchParams from "hooks/useCustomSearchParams"
import useDispatch from "hooks/useDispatch"
import UserPermissions from "components/UserPermissions"
import {useSelector} from "react-redux"
import {getLoading} from "redux/selectors/definition"
import {Spin} from "antd"
import ServiceAccountInfo from "components/ServiceAccountInfo"

const ServiceAccountBridge: FC = () => {
  const [searchParams, setSearchParams] = useCustomSearchParams()
  const dispatch = useDispatch()

  const loading = useSelector(getLoading)

  useEffect(() => {
    if (searchParams?.id) {
      dispatch.definition.loadSubjectPermissions({subjectId: searchParams.id, subjectType: "service_account"})
    }
  }, [dispatch.definition, searchParams?.id])

  return <div>
    <ServiceAccountInfo id={searchParams?.id}/>
    <br/>
    <br/>
    {loading ? <Spin/> : <UserPermissions/>}
  </div>
}


export default ServiceAccountBridge