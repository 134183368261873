import React, {FC} from "react"
import {useSelector} from "react-redux"
import {getServicesAccounts} from "redux/selectors/definition"
import {Descriptions, Empty} from "antd"

interface Props {
  id: string,
}

const ServiceAccountInfo: FC<Props> = ({id}) => {
  const services = useSelector(getServicesAccounts)

  const serviceAccount = services?.find((elem) => elem.id === Number(id))

  if (!serviceAccount) {
    return <Empty/>
  }
  return <Descriptions title="Info" bordered={true}>
    <Descriptions.Item label="Username">{serviceAccount.username}</Descriptions.Item>
    <Descriptions.Item label="Id">{serviceAccount.id}</Descriptions.Item>
    <Descriptions.Item label="Description">{serviceAccount.description}</Descriptions.Item>
  </Descriptions>
}


export default ServiceAccountInfo