import React, {FC, useEffect} from "react"
import useDispatch from "hooks/useDispatch"
import {useSelector} from "react-redux"
import {Outlet} from "react-router-dom"
import {isAuthenticated} from "redux/selectors/environment"
import {Spin} from "antd"


const FullAdminBridge: FC = () => {
  const dispatch = useDispatch()

  const isAuth = useSelector(isAuthenticated)

  useEffect(() => {
    if (isAuth) {
      dispatch.definition.loadTypes()
      dispatch.definition.loadUsers()
      dispatch.definition.loadServiceAccounts()
      dispatch.definition.loadCustomers()
    }
  }, [dispatch.definition, isAuth])

  if (!isAuth) {
    return <Spin/>
  }

  return <Outlet/>
}

export default FullAdminBridge