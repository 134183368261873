import {RootState} from "redux/store"

export const getTypes = (state: RootState) => state.definition?.types
export const getResourceIds = (state: RootState) => state.definition?.resourceIds

export const getRelationships = (state: RootState) => state.definition?.definition.relationships

export const getPermissions = (state: RootState) => state.definition?.definition.permissions
export const getUserPermissions = (state: RootState) => state.definition?.userPermissions

export const getUsers = (state: RootState) => state?.definition?.users
export const getUsersMap = (state: RootState) => state?.definition?.usersMap
export const getServicesAccounts = (state: RootState) => state?.definition?.serviceAccounts

export const getCustomers = (state: RootState) => state?.definition?.customers
export const getLoading = (state: RootState) => state?.definition?.loading
