import Api from "services/api"
import {ServiceAccountDtoCreateOrUpdate, ServiceAccountDtoDetail} from "models/User"

export const listServiceAccounts = async (): Promise<ServiceAccountDtoDetail[]> => Api.get<void, ServiceAccountDtoDetail[]>(`/serviceAccount`, {
  data: {
    failForbiddenSilently: true,
  },
})

export const createServiceAccount = async (customerAbbrev: string, dto: ServiceAccountDtoCreateOrUpdate): Promise<{
  serviceAccount: ServiceAccountDtoDetail,
  password: string
}> =>
  Api.post<void, { serviceAccount: ServiceAccountDtoDetail, password: string }>(`/serviceAccount?customerAbbrev=${customerAbbrev}`, dto)

export const deleteServiceAccount = async (id: number): Promise<void> =>
  Api.delete<void, void>(`/serviceAccount/${id}`)

export const resetServiceAccountPassword = async (id: number): Promise<{ serviceAccount: ServiceAccountDtoDetail, password: string }> =>
  Api.put<void, { serviceAccount: ServiceAccountDtoDetail, password: string }>(`/serviceAccount/${id}/resetPassword`)