import React, {ReactNode} from "react"
import {SentryService} from "services"
import {Button, Result} from "antd"
import styled from "styled-components"

interface State {
  hasError: boolean
}

interface Props {
  children: ReactNode
}

export default class GlobalErrorBoundary extends React.Component<Props, State> {
  protected actions: ReactNode[]

  constructor(props: Props) {
    super(props)
    this.state = {hasError: false}
    this.actions = [<Button type={"primary"} key={"reload"} onClick={this.handleReload}>Reload</Button>]
  }

  static getDerivedStateFromError() {
    return {hasError: true}
  }

  handleReload = () => {
    window.location.reload()
  }

  override componentDidCatch(error: Error) {
    SentryService.captureError("Error boundary error", [], error)
  }

  override render() {
    if (this.state.hasError) {
      return <StyledDiv>
        <Result
          status={"error"}
          title="An error occurred"
          extra={this.actions}/>
      </StyledDiv>
    }
    return this.props.children
  }
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100%;
`
