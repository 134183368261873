import * as Sentry from '@sentry/browser'
import {BrowserTracing} from "@sentry/tracing"

let dsn: string | undefined
export const init = (url?: string) => {
  if (!url) {
    // eslint-disable-next-line no-console
    console.warn("Could not retrieve Sentry DSN")
  }
  dsn = url
  if (dsn && process.env.NODE_ENV !== "development") {
    Sentry.init({
      dsn: url,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.01, // very low sample rate as we have limited number in sentry free tier
      environment: 'production', // always production, devel/test are using a different project
      release: process.env.REACT_APP_VERSION,
    })
  }
}

export const configureScope = (username: string) => {
  const user = {username}
  Sentry.configureScope(scope => scope.setUser(user))
}

export const captureError = (errorMessage: string, tags: Record<string, any> = {}, cause?: Error | string) => {
  const error = cause
    ? cause ?? new Error(cause)
    : new Error(errorMessage)
  if (dsn && process.env.NODE_ENV !== "development") {
    Sentry.withScope(scope => {
      scope.setLevel("error")
      Object.keys(tags).forEach((key) => {
        scope.setTag(key, tags[key])

      })
      Sentry.captureException(error)
    })
  } else {
    // eslint-disable-next-line no-console
    console.warn('sentry is not configured although an error was thrown', error, tags)
  }
}

export const captureWarn = (message: string) => {
  // eslint-disable-next-line no-undef
  if (dsn && process.env.NODE_ENV !== "development") {
    // eslint-disable-next-line no-console
    console.warn(message)
    Sentry.captureMessage(message)
  } else {
    // eslint-disable-next-line no-console
    console.warn('sentry is not configured although a warning was thrown', message)
  }
}
