import React, {FC, useEffect} from "react"
import useCustomSearchParams from "hooks/useCustomSearchParams"
import CustomerInfo from "components/CustomerInfo"
import useDispatch from "hooks/useDispatch"
import {useSelector} from "react-redux"
import {getCustomers} from "redux/selectors/definition"

const CustomerBridge: FC = () => {
  const [searchParams, setSearchParams] = useCustomSearchParams()
  const dispatch = useDispatch()

  const customers = useSelector(getCustomers)

  const customer = customers?.find((elem) => String(elem.id) === searchParams?.id)

  useEffect(() => {
    if (customer) {
      dispatch.definition.loadObjectReferencesDefinition([{type: "customer", id: customer.abbreviation}, {type: "root", id: "biron"}])
    }
  }, [dispatch.definition, customer])

  return <div>
    <CustomerInfo customer={customer}/>
  </div>
}


export default CustomerBridge