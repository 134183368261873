import Api from "services/api"
import {AppliedPermissionDto, Definitions, RelationshipDto, ResourceToDefinition} from "models/Definition"

export const getTypes = async (): Promise<ResourceToDefinition> => Api.get<void, ResourceToDefinition>(`/resources/types`, {
  data: {
    failForbiddenSilently: true,
  },
})

export type SubjectType = "user" | "service_account"

export const listResourceIds = async (resource: string): Promise<string[]> => Api.get<void, string[]>(`/resources/ids?type=${resource}`)

export const listResourcesAndPermissions = async (resource: string, id: string): Promise<Definitions> => Api.get<void, Definitions>(`/resources/state?type=${resource}&id=${id}`)

export const listSubjectPermissions = async (subjectId: string, subjectType: SubjectType): Promise<AppliedPermissionDto[]> =>
  Api.get<void, AppliedPermissionDto[]>(`/resources/permissions?subjectId=${subjectId}&subjectType=${subjectType}`)


export const addRelationship = async (relationshipDto: RelationshipDto) => Api.post<void, RelationshipDto>(`/resources/relationship`, relationshipDto)
export const deleteRelationship = async (relationshipDto: RelationshipDto) => Api.delete<void, RelationshipDto>(`/resources/relationship`, {
  data: relationshipDto,
})

export const addBironAnalystRelationship = async (customerAbbrev: string, userId: string) => Api.post<void, RelationshipDto>(`/resources/bironAnalystRelationship?customerAbbrev=${customerAbbrev}&userId=${userId}`)
export const deleteBironAnalystRelationship = async (customerAbbrev: string, userId: string) => Api.delete<void, RelationshipDto>(`/resources/bironAnalystRelationship?customerAbbrev=${customerAbbrev}&userId=${userId}`)