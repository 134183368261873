import React, {FC, useCallback} from "react"
import {SourceDatabaseType} from "models/SourceDatabase"
import {Button, Checkbox, Form, Input, Select} from "antd"
import {useDispatch} from "hooks"
import styled from "styled-components"

interface Props {
  versionCodes: string[]
}

interface FormValues {
  name: string,
  type: string,
  versionCode: string,
  toBackup: boolean
}

const AddSourceDatabase: FC<Props> = ({versionCodes}) => {
  const [form] = Form.useForm<FormValues>();
  const dispatch = useDispatch()

  const onFinish = useCallback((values: FormValues) => {
    dispatch.definition.addSourceDatabase({
        versionCode: values.versionCode,
        dto: {
          name: values.name,
          type: values.type,
          toBackup: values.toBackup,
        },
      })
      .then(() => form.resetFields())
      .catch(() => {
      })

  }, [dispatch.definition, form])

  const chooseVersionCodeForm = versionCodes.length > 1 ? <Form.Item label="Version code" name={"versionCode"} rules={[{required: true}]}>
    <Select showSearch
            style={{width: 200}}
            options={versionCodes.sort().map((version, i) => ({label: version, value: version, key: version + i}))}
    />
  </Form.Item> : <Form.Item label="Version code" name={"versionCode"} rules={[{required: true}]}>
    <Input
      style={{width: 200}}
      value={versionCodes[0]}
      disabled
    />
  </Form.Item>

  return (
    <StyledFieldset>
      <legend>Add source database</legend>
      <Form
        layout={'inline'}
        form={form}
        initialValues={{versionCode: versionCodes.length > 1 ? null : versionCodes[0], type: SourceDatabaseType.LIVE, toBackup: false}}
        onFinish={onFinish}
      >
        {chooseVersionCodeForm}
        <Form.Item label="Database name" name={"name"} rules={[{required: true}]}>
          <Input
            style={{width: 200}}
            placeholder={`ga4, spreadsheets, ...`}/>
        </Form.Item>
        <Form.Item label="Type" name={"type"} rules={[{required: true}]}>
          <Select showSearch
                  style={{width: 200}}
                  options={Object.values(SourceDatabaseType).map(dbType => ({label: dbType, value: dbType}))}
          />
        </Form.Item>
        <Form.Item label="Backup" name={"toBackup"} rules={[{required: true}]} valuePropName="checked">
          <Checkbox/>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType={"submit"}>Add Source Database</Button>
        </Form.Item>
      </Form></StyledFieldset>
  );
}

const StyledFieldset = styled("fieldset")`
    box-shadow: rgba(169, 169, 169, 0.5) 0 0 1px;
    border: none;
    border-radius: 10px;
`

export default AddSourceDatabase