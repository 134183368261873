import {NavigateOptions, URLSearchParamsInit, useSearchParams} from "react-router-dom"

const useCustomSearchParams = (): [Record<string, string>, (nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit), navigateOpts?: NavigateOptions) => void] => {
  const [search, setSearch] = useSearchParams();
  const searchAsObject: { [p: string]: string } = Object.fromEntries(
    new URLSearchParams(search),
  );

  return [searchAsObject, setSearch];
}


export default useCustomSearchParams