import React, {useCallback, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {EnvironmentSelectors} from "./redux/selectors"
import {BrowserRouter as Router, useNavigate} from 'react-router-dom'
import useDispatch from "hooks/useDispatch"
import {Spin} from "antd"
import AppContainer from "AppContainer"
import {AuthProvider, AuthProviderProps} from 'react-oidc-context'
import {oidcConfigTemplate, onSigninCallback} from "@biron-data/react-oidc"

const AppSecuredContent = () => {
  const dispatch = useDispatch()
  const consts = useSelector(EnvironmentSelectors.getConsts)
  const loadedConsts = Boolean(consts.OIDC_ISSUER)

  const navigate = useNavigate()

  useEffect(() => {
    dispatch.environment.loadStatic()
  }, [dispatch])

  const oidcConfig: AuthProviderProps = {
    ...oidcConfigTemplate,
    authority: consts?.OIDC_ISSUER ?? "",
    // eslint-disable-next-line camelcase
    client_id: consts?.OIDC_CLIENTID ?? "",
    onSigninCallback: useCallback(() => onSigninCallback(navigate), [navigate]),
  }

  return loadedConsts
    ? <AuthProvider
      {...oidcConfig}
    >
      <AppContainer/>
    </AuthProvider>
    : <Spin spinning={true}></Spin>
}

const AppSecured = () => {
  return <Router>
    <AppSecuredContent/>
  </Router>
}

export default AppSecured
