import React, {FC, useMemo} from "react"
import {Form, Input, Select} from 'antd';
import {useSelector} from "react-redux"
import {getServicesAccounts, getUsers} from "redux/selectors/definition"

interface Props {
  subjectType: string
  formItemName: string
}

const SubjectPicker: FC<Props> = ({subjectType, formItemName}) => {
  const users = useSelector(getUsers)
  const serviceAccounts = useSelector(getServicesAccounts)

  const input = useMemo(() => {
    switch (subjectType) {
      case "user":
        return <Select
          placeholder={"Choose user"}
          showSearch
          style={{width: 300}}
          options={users.map(resource => ({label: resource.email, value: resource.id}))}
          filterOption={(userInput, option) =>
            (option?.label ?? '').toLowerCase().includes(userInput.toLowerCase())
          }
        />
      case "service_account":
        return <Select
          placeholder={"Choose service account"}
          showSearch
          style={{width: 300}}
          options={serviceAccounts.map(resource => ({label: resource.username, value: String(resource.id)}))}
          filterOption={(userInput, option) =>
            (option?.label ?? '').toLowerCase().includes(userInput.toLowerCase())
          }
        />
      default:
        return <Input placeholder="subject id"/>
    }

  }, [serviceAccounts, subjectType, users])

  return <Form.Item label="Subject Id" name={formItemName}
                    rules={[{
                      required: true,
                      pattern: /^\w+$/
                    }]}
  >
    {input}
  </Form.Item>
}


export default SubjectPicker