import React, {FC, useCallback} from "react"
import {Button, Form, notification, Select} from 'antd';
import useDispatch from "hooks/useDispatch"
import {useSelector} from "react-redux"
import {getUsers} from "redux/selectors/definition"

interface Props {
  excludedUsers: string[],
  customerAbbreviation: string
}

interface FormValues {
  subjectId: string,
}

const BironAnalystCreator: FC<Props> = ({excludedUsers, customerAbbreviation}) => {
  const [form] = Form.useForm<FormValues>();
  const dispatch = useDispatch()
  const users = useSelector(getUsers)

  const onFinish = useCallback((values: FormValues) => {
    dispatch.definition.addBironAnalystRelationship({
        userId: values.subjectId,
        customerAbbrev: customerAbbreviation,
      })
      .then(() => {
        form.resetFields()
        notification.info({
          message: "Role Biron analyst granted",
          description: "Make sure to refresh Kirbytes state using Kirbytes-Manager.",
        })

      })
      .catch(() => {
      })
  }, [customerAbbreviation, dispatch.definition, form])

  return (
    <Form
      layout={'inline'}
      form={form}
      initialValues={{layout: 'inline'}}
      onFinish={onFinish}
    >
      <Form.Item name={"subjectId"}
                 rules={[{
                   required: true,
                 }]}
      >
        <Select
          placeholder={"Biron user"}
          showSearch
          style={{width: 200}}
          options={users
            .filter((user) => user.email.endsWith("@biron-analytics.com") && !user.email.startsWith("machine") && !excludedUsers.includes(user.email))
            .map(resource => ({label: resource.email, value: resource.id}))}
          filterOption={(userInput, option) =>
            (option?.label ?? '').toLowerCase().includes(userInput.toLowerCase())
          }
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType={"submit"}>Grant Biron analyst</Button>
      </Form.Item>
    </Form>
  );
}


export default BironAnalystCreator