import React, {FC} from "react"
import {VersionDtoDetail} from "models/Version"
import EnvironmentInfo from "components/EnvironmentInfo"
import SourceDatabaseList from "components/SourceDatabaseList"
import AddSourceDatabase from "components/AddSourceDatabase"
import {StyledCard} from "components/StyledCard"
import {Col, Row} from "antd"

interface Props {
  version: VersionDtoDetail,
}

const VersionInfo: FC<Props> = ({version}) => {
  return <StyledCard title={<div>Version - {version.code}</div>}>
    {/*<Descriptions title="Sequence Info" bordered={true}>*/}
    {/*  <Descriptions.Item label="Id">{version.id}</Descriptions.Item>*/}
    {/*  <Descriptions.Item label="Code">{version.code}</Descriptions.Item>*/}
    {/*</Descriptions>*/}
    <StyledCard title={"Source Databases"}>
      <Row>
        <Col span={24}>
          <AddSourceDatabase versionCodes={[version.code]}/>
        </Col>
      </Row>
      <br/>
      <br/>
      <Row>
        <Col span={24}>
          <SourceDatabaseList sourceDatabases={version.sourceDatabases}/>
        </Col>
      </Row>
    </StyledCard>


    {version.environments.map((elem, i) => <EnvironmentInfo environment={elem} key={i}/>)}
  </StyledCard>
}


export default VersionInfo