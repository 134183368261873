import React, {FC} from "react"
import {useSelector} from "react-redux"
import {getUsers} from "redux/selectors/definition"
import {Descriptions, Empty} from "antd"

interface Props {
  userId: string,
}

const UserInfo: FC<Props> = ({userId}) => {
  const users = useSelector(getUsers)

  const user = users?.find((elem) => elem.id === userId)

  if (!user) {
    return <Empty/>
  }
  return <Descriptions title="User Info" bordered={true}>
    <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
    <Descriptions.Item label="Id">{user.id}</Descriptions.Item>
    <Descriptions.Item label="First Name">{user.firstName}</Descriptions.Item>
    <Descriptions.Item label="Last Name">{user.lastName}</Descriptions.Item>
    <Descriptions.Item label="Locale">{user.locale}</Descriptions.Item>
  </Descriptions>
}


export default UserInfo