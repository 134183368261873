import React, {useMemo} from 'react'
import './App.css'
// import "antd/dist/antd.css"
// import "antd/dist/antd.dark.css"
import {Provider} from "react-redux"
import AppSecured from "AppSecured"
import store from "redux/store"
import GlobalErrorBoundary from "GlobalErrorBoundary"
import {ConfigProvider, theme} from "antd";

const App = () => {
  const appliedTheme = useMemo(() => {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return theme.darkAlgorithm
    } else {
      return theme.darkAlgorithm // theme.defaultAlgorithm force dark mode for now
    }
  }, [])
  return <ConfigProvider
    theme={{
      algorithm: appliedTheme,
    }}
  >
    <GlobalErrorBoundary>
      <Provider store={store}>
        <AppSecured/>
      </Provider>
    </GlobalErrorBoundary>
  </ConfigProvider>
}

export default App
