import React, {useCallback, useEffect, useState} from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {useDispatch} from "./hooks"
import {Button, Modal} from "antd"
import {RequiredAuth} from "SecureRoute"
import FullAdminRoot from "components/FullAdminRoot"
import ResourceBridge from "components/ResourceBridge"
import AppLayout from "./AppLayout";
import FullAdminBridge from "components/FullAdminBridge"
import UserBridge from "components/UserBridge"
import CustomerBridge from "components/CustomerBridge"
import ServiceAccountBridge from "components/ServiceAccountBridge"
import {useAuth} from "react-oidc-context"
import {captureError, captureWarn} from "services/SentryService"
import {getTargetUrl, useRenewToken} from "@biron-data/react-oidc"
import {getConsts} from "redux/selectors/environment"
import {useSelector} from "react-redux"


const AppContainer = () => {
  const dispatch = useDispatch()
  const auth = useAuth()
  const consts = useSelector(getConsts)

  const [sessionExpired, setSessionExpired] = useState<boolean>(false)
  const [hasNotifiedSessionExpired, setHasNotifiedSessionExpired] = useState<boolean>(false)


  useRenewToken(() => {
    setSessionExpired(true)
  }, consts?.OIDC_ISSUER)

  useEffect(() => {
    if (auth.isAuthenticated && auth.user?.access_token) {
      dispatch.environment.setAuthToken({
        auth,
        authToken: auth.user?.access_token,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch.environment, auth.user?.access_token]);

  useEffect(() => {
    if (auth.isAuthenticated && auth.user) {
      dispatch.environment.initAuth({
          accessToken: auth.user.access_token,
          auth,
        })
        .catch((err: Error) => {
          // eslint-disable-next-line no-console
          console.warn('fail to checkAuthentication -> logout', err)
          dispatch.environment.logout({})
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated, auth.user?.profile.sub, dispatch.environment])

  const triggerNewAuthFlow = useCallback(() => {
    window.location.reload();
  }, []);

  const mustTriggerNewAuthFlow = useCallback(() => {
    Modal.info({
      title: "Session Expired",
      content: (
        <div>
          <p>Press the button below to reconnect</p>
        </div>
      ),
      okText: "Continue",
      onOk: triggerNewAuthFlow,
    });
  }, [triggerNewAuthFlow]);

  useEffect(() => {
    if (sessionExpired && !hasNotifiedSessionExpired) {
      setHasNotifiedSessionExpired(true)
      mustTriggerNewAuthFlow()
    }
  }, [hasNotifiedSessionExpired, mustTriggerNewAuthFlow, sessionExpired]);

  if (auth.activeNavigator === "signoutRedirect") {
    return <div>Loading...</div>;
  }

  if (auth.error && !sessionExpired) {
    if (auth.error.message === "No matching state found in storage") {
      // user has tried to login in two different tabs at the same time: local storage cannot follow
      captureWarn(auth.error.message)
      window.location.replace(window.location.origin + getTargetUrl())
    } else {
      captureError(auth.error.message)
      dispatch.environment.logout({auth})
    }
    return <div>Loading...</div>;
  }

  return <Routes>
    <Route path="*" element={<RequiredAuth/>}>
      <Route path={""} element={<AppLayout content={<Outlet/>}/>}>
        <Route path="app" element={<RequiredAuth/>}>
          <Route path="i" element={<div>IFrames root<Outlet/></div>}>
            <Route path="create-user" element={<Button type={"primary"}>Create User</Button>}/>
          </Route>
          <Route path="full-admin" element={<FullAdminBridge/>}>
            <Route path="resource" element={<ResourceBridge/>}/>
            <Route path="user" element={<UserBridge/>}/>
            <Route path="service_account" element={<ServiceAccountBridge/>}/>
            <Route path="customer" element={<CustomerBridge/>}/>
            <Route path="" element={<FullAdminRoot/>}></Route>
          </Route>
        </Route>
        <Route
          path="*"
          element={<Navigate to="/app/full-admin" replace/>}
        />
        <Route
          path=""
          element={<Navigate to="/app/full-admin" replace/>}
        />
      </Route>
    </Route>
  </Routes>
}

export default AppContainer