import React, {FC, useMemo, useRef, useState} from "react"
import {SourceDatabaseDtoDetail, SourceDatabaseType} from "models/SourceDatabase"
import {Button, Input, Popconfirm, Space, Table} from "antd"
import {ColumnsType, ColumnType} from "antd/es/table"
import {DeleteOutlined, SearchOutlined} from '@ant-design/icons';
import {useDispatch} from "hooks"

interface Props {
  sourceDatabases: SourceDatabaseDtoDetail[],
}

interface DataRow {
  code: string,
  type: string,
  toBackup: boolean
}

const SourceDatabaseList: FC<Props> = ({sourceDatabases}) => {
  const searchInput = useRef(null);
  const dispatch = useDispatch()
  const [filterByCode, setFilterByCode] = useState("")
  const getColumnSearchProps = (dataIndex: keyof DataRow): ColumnType<DataRow> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm}) => (
      <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined/>}
            size="small"
            style={{width: 90}}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{color: filtered ? '#1677ff' : undefined}}/>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        // @ts-ignore
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const columns: ColumnsType<DataRow> = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code.localeCompare(b.code),
      // ...getColumnSearchProps('code'),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      filters: Object.values(SourceDatabaseType).map((type => ({
        text: type,
        value: type,
      }))),
      filterSearch: true,
      onFilter: (value, record: DataRow) => record.type === String(value),
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: 'Backup',
      dataIndex: 'backup',
      key: 'toBackup',
      filters: [true, false].map((toBackup => ({
        text: toBackup ? 'yes' : 'no',
        value: toBackup,
      }))),
      filterSearch: true,
      onFilter: (value, record: DataRow) => record.toBackup === value,
      sorter: (a, b) => Number(a.toBackup) - Number(b.toBackup),
      render: (value, record, index) => record.toBackup ? <div>yes</div> : <div>no</div>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        // <Space size="middle">
        <Popconfirm
          title="Source database deletion"
          description={`Do you really want to delete the database ${record.code} ?`}
          onConfirm={() => dispatch.definition.deleteSourceDatabase(record.code)}
          okText="Yes"
          cancelText="No"
        >
          <Button icon={<DeleteOutlined/>} danger>Delete</Button>
        </Popconfirm>
        // </Space>
      ),
    },
  ];
  const dataSource = useMemo(() =>
    sourceDatabases
      .filter((elem) => filterByCode === "" || filterByCode === undefined || elem.code.toLowerCase().includes(filterByCode.toLowerCase()))
      .sort((a, b) => a.code.localeCompare(b.code)).map((db: SourceDatabaseDtoDetail) => ({
      code: db.code,
      type: db.type,
      toBackup: db.toBackup,
      key: db.code,
    })), [filterByCode, sourceDatabases])

  return <div>
    <Input
      placeholder={"Search by code"}
      value={filterByCode}
      onChange={(e) => setFilterByCode(e.target.value)}
      style={{marginBottom: "4px"}}
    />
    <br/>
    <Table
      size={"small"}
      bordered
      dataSource={dataSource}
      columns={columns}
      pagination={{
        defaultPageSize: 15,
      }}/>
  </div>
}

export default SourceDatabaseList