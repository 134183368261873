import React from 'react'
import {withAuthenticationRequired} from "react-oidc-context";
import {Outlet} from "react-router-dom"
import {storeTargetUrl} from "@biron-data/react-oidc"

export const RequiredAuth: React.FC = withAuthenticationRequired(Outlet, {
  OnRedirecting: () => {
    return <div>Redirecting to the login page...</div>
  },
  onBeforeSignin: () => {
    storeTargetUrl()
  },
})