import React, {FC, useCallback} from "react"
import {CustomerDtoDetail} from "models/Customer"
import {useSelector} from "react-redux"
import {getServicesAccounts} from "redux/selectors/definition"
import {Button, List, Popconfirm} from "antd"
import {Link} from "react-router-dom"
import useDispatch from "hooks/useDispatch"
import {DeleteOutlined} from "@ant-design/icons"

interface Props {
  customer: CustomerDtoDetail,
}

const ServiceAccountList: FC<Props> = ({customer}) => {
  const services = useSelector(getServicesAccounts)
  const dispatch = useDispatch()

  const onDeleteServiceAccount = useCallback((id: number) => {
    dispatch.definition.deleteServiceAccount({
        id,
      })
      .then(() => {
      })
      .catch(() => {
      })
  }, [dispatch.definition])

  const onResetServiceAccountPassword = useCallback((id: number) => {
    dispatch.definition.resetServiceAccountPassword({
        id,
      })
      .then(() => {
      })
      .catch(() => {
      })
  }, [dispatch.definition])

  const serviceAccounts = services?.filter((elem) => elem.customer.id === customer.id)

  const listData = serviceAccounts.map((elem, i) => ({
    title: elem.username,
    description: `${elem.description}`,
    id: elem.id,
  }))

  return <List
    itemLayout="horizontal"
    dataSource={listData}
    renderItem={(item) => (
      <List.Item actions={
        [
          <Popconfirm
            title="Password reset"
            description={`Do you really want to reset the password ?`}
            onConfirm={() => onResetServiceAccountPassword(item.id)}
            okText="Yes"
            cancelText="No"
            key={1}
          >
            <Button type={"default"}>Reset password</Button>
          </Popconfirm>,
          <Popconfirm
            title="Source database deletion"
            description={`Do you really want to delete this service account ?`}
            onConfirm={() => onDeleteServiceAccount(item.id)}
            okText="Yes"
            cancelText="No"
            key={2}
          >
            <Button icon={<DeleteOutlined/>} type={"primary"} danger>Delete</Button>
          </Popconfirm>,
        ]
      }>
        <List.Item.Meta
          title={
            <Link to={`/app/full-admin/service_account?id=${item.id}`}>{item.title}</Link>}
          description={item.description}
        />
      </List.Item>
    )}
  />
}


export default ServiceAccountList