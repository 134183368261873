import React, {FC, useState} from "react"
import {useSelector} from "react-redux"
import {getCustomers, getServicesAccounts, getTypes, getUsers} from "redux/selectors/definition"
import {Link} from "react-router-dom"
import {Button, Col, Row, Select, Space} from "antd"
import {RobotOutlined, UserOutlined} from '@ant-design/icons';
import SourceDatabaseList from "components/SourceDatabaseList"
import AddSourceDatabase from "components/AddSourceDatabase"
import {StyledCard} from "components/StyledCard"

const FullAdminRoot: FC = () => {
  const resourceToDefinition = useSelector(getTypes)
  const users = useSelector(getUsers)
  const servicesAccounts = useSelector(getServicesAccounts)
  const customers = useSelector(getCustomers)

  const [selectedUserId, setSelectedUserId] = useState("")
  const [selectedServiceAccountId, setSelectedServiceAccountId] = useState("")
  const [selectedCustomerId, setSelectedCustomerIdId] = useState("")
  const [selectedResourceId, setSelectedResourceId] = useState("")

  return <><Row>
    <Col span={8}>
      <StyledCard title={"Authz Resources"}>
        <Space>
          <Select
            placeholder={"Choose resource"}
            value={selectedResourceId}
            showSearch
            style={{width: "15vw"}}
            options={Object.keys(resourceToDefinition).sort().map(resource => ({label: resource, value: resource}))}
            onSelect={(e) => setSelectedResourceId(e)}
            filterOption={(userInput, option) =>
              (option?.label ?? '').toLowerCase().includes(userInput.toLowerCase())
            }
          />
          <Link to={`/app/full-admin/resource?name=${selectedResourceId}`}>
            <Button
              type={"primary"}
              disabled={!selectedResourceId}
            >
              Details
            </Button>
          </Link>
        </Space>
      </StyledCard>
    </Col>
    <Col span={8}>
      <StyledCard title={"Users"}>
        <Space>
          <Select
            placeholder={"Choose user"}
            value={selectedUserId}
            showSearch
            style={{width: "15vw"}}
            options={users
              .sort((a, b) => a.email.localeCompare(b.email))
              .map(resource => ({label: resource.email, value: resource.id}))}
            onSelect={(e) => setSelectedUserId(e)}
            filterOption={(userInput, option) =>
              (option?.label ?? '').toLowerCase().includes(userInput.toLowerCase())
            }
          />
          <Link to={`/app/full-admin/user?id=${selectedUserId}`}>
            <Button
              icon={<UserOutlined/>}
              type={"primary"}
              disabled={!selectedUserId}
            >
              Details
            </Button>
          </Link>
        </Space>
      </StyledCard>
    </Col>
    <Col span={8}>
      <StyledCard title={"Service Accounts"}>
        <Space>
          <Select
            placeholder={"Choose service accounts"}
            value={selectedServiceAccountId}
            showSearch
            style={{width: "15vw"}}
            options={servicesAccounts
              .sort((a, b) => a.username.localeCompare(b.username))
              .map(resource => ({label: resource.username, value: resource.id}))}
            onSelect={(e) => setSelectedServiceAccountId(e)}
            filterOption={(userInput, option) =>
              (option?.label ?? '').toLowerCase().includes(userInput.toLowerCase())
            }
          />
          <Link to={`/app/full-admin/service_account?id=${selectedServiceAccountId}`}>
            <Button
              icon={<RobotOutlined/>}
              type={"primary"}
              disabled={!selectedServiceAccountId}
            >
              Details
            </Button>
          </Link>
        </Space>
      </StyledCard>
    </Col>
    <Col span={8}>
      <StyledCard title={"Customers"}>
        <Space>
          <Select
            placeholder={"Choose customer"}
            value={selectedCustomerId}
            showSearch
            style={{width: "15vw"}}
            options={customers
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(resource => ({label: resource.name, value: resource.id}))}
            onSelect={(e) => setSelectedCustomerIdId(e)}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
          <Link to={`/app/full-admin/customer?id=${selectedCustomerId}`}>
            <Button
              type={"primary"}
              disabled={!selectedCustomerId}
            >
              Details
            </Button>
          </Link>
        </Space>
      </StyledCard>
    </Col>
  </Row>
    <Row>
      <StyledCard title={"Source Databases"}>
        <Row>
          <Col span={24}>
            <AddSourceDatabase versionCodes={customers?.flatMap((customer) =>
              customer.sequences.flatMap((sequence) =>
                sequence.versions.map((version) =>
                  version.code))) ?? []}/>
          </Col>
        </Row>
        <br/>
        <br/>
        <Row>
          <Col span={24}>
            <SourceDatabaseList
              sourceDatabases={customers?.flatMap((customer) =>
                customer.sequences.flatMap((sequence) =>
                  sequence.versions.flatMap((version) =>
                    version.sourceDatabases))) ?? []}/>
          </Col>
        </Row>
      </StyledCard>
    </Row>
  </>
}

export default FullAdminRoot