import React, {FC, useCallback, useState} from "react"
import {Button, Form, Input, Select} from 'antd';
import useDispatch from "hooks/useDispatch"
import SubjectPicker from "components/SubjectPicker"

interface Props {
  resources: string[]
  relations: string[]
  currentResourceType: string
  currentResourceId?: string
}

interface FormValues {
  resourceId: string
  relation: string,
  subjectType: string,
  subjectId: string,
}

const RelationshipCreator: FC<Props> = ({resources, relations, currentResourceType, currentResourceId}) => {
  const [form] = Form.useForm<FormValues>();
  const dispatch = useDispatch()

  const onFinish = useCallback((values: FormValues) => {
    dispatch.definition.addRelationship({
        relation: values.relation,
        resource: {
          type: currentResourceType,
          id: currentResourceId ?? values.resourceId
        },
        subject: {
          type: values.subjectType,
          id: values.subjectId,
        },
      })
      .then(() => form.resetFields())
      .catch(() => {
      })

  }, [currentResourceId, currentResourceType, dispatch.definition, form])

  const [subjectType, setSubjectType] = useState("")

  return (
    <Form
      layout={'inline'}
      form={form}
      initialValues={{layout: 'inline'}}
      onFinish={onFinish}
    >
      {!currentResourceId &&
        <Form.Item label="Resource Id" name={"resourceId"} rules={[{required: true}]}>
          <Input
            style={{width: 200}}
            placeholder={`New ${currentResourceType} id`}/>
        </Form.Item>
      }
      <Form.Item label="Relation" name={"relation"} rules={[{required: true}]}>
        <Select showSearch
                style={{width: 200}}
                options={relations.map(relation => ({label: relation, value: relation}))}
        />
      </Form.Item>
      <Form.Item label="Subject Type" name={"subjectType"} rules={[{required: true}]}>
        <Select
          showSearch
          style={{width: 200}}
          options={resources.map(resource => ({label: resource, value: resource}))}
          onChange={(e) => {
            setSubjectType(e)
            form.setFieldValue("subjectId", "")
          }}
        />
      </Form.Item>
      <SubjectPicker subjectType={subjectType} formItemName={"subjectId"}/>
      <Form.Item>
        <Button type="primary" htmlType={"submit"}>Add Relationship</Button>
      </Form.Item>
    </Form>
  );
}


export default RelationshipCreator