import React, {FC} from "react"
import {Col, Divider, Empty, Row} from "antd"
import SequenceInfo from "components/SequenceInfo"
import {StyledCard} from "components/StyledCard"
import CustomerEdition from "components/CustomerEdition"
import AnalystList from "components/AnalystList"
import ServiceAccountList from "components/ServiceAccountList"
import {CustomerDtoDetail} from "models/Customer"
import ServiceAccountCreator from "components/ServiceAccountCreator"

interface Props {
  customer?: CustomerDtoDetail,
}

const CustomerInfo: FC<Props> = ({customer}) => {
  if (!customer) {
    return <Empty/>
  }
  return <StyledCard title={<div>Customer - {customer.name}</div>} extra={<div>{customer.abbreviation}</div>}>
    <Row gutter={{xs: 8, sm: 16, md: 24}}>
      <Col className="gutter-row" span={8}>
        <StyledCard title={"Settings"}>
          <CustomerEdition customer={customer}/>
        </StyledCard>
      </Col>
      <Col className="gutter-row" span={8}>
        <StyledCard title={"Analysts"}>
          <AnalystList customer={customer}/>
        </StyledCard>
      </Col>
      <Col className="gutter-row" span={8}>
        <StyledCard title={"Service Accounts"}>
          <ServiceAccountCreator customerAbbreviation={customer.abbreviation}/>
          <Divider/>
          <ServiceAccountList customer={customer}/>
        </StyledCard>
      </Col>
    </Row>

    {customer.sequences.map((elem, i) => <SequenceInfo sequence={elem} key={i}/>)}
  </StyledCard>
}


export default CustomerInfo

