import React, {FC, useMemo} from "react"
import {useSelector} from "react-redux"
import {getUserPermissions} from "redux/selectors/definition"
import {Button, Table} from "antd"
import {ColumnsType} from "antd/es/table";
import {Link} from "react-router-dom"

interface DataRow {
  permission: string,
  resourceType: string,
  resourceId: string,
}

const UserPermissions: FC = () => {
  const permissions = useSelector(getUserPermissions)


  const uniquePermissions = useMemo(() => [...new Set(permissions?.map(elem => elem.permission))], [permissions])
  const uniqueResourceType = useMemo(() => [...new Set(permissions?.map(elem => elem.objectReference.type))], [permissions])
  const uniqueResourceId = useMemo(() => [...new Set(permissions?.map(elem => elem.objectReference.id))], [permissions])


  const columns: ColumnsType<DataRow> = [{
    title: "Permission",
    dataIndex: "permission",
    key: 'permission',
    filters: uniquePermissions.map((perm => ({
      text: perm,
      value: perm,
    }))),
    filterMode: "tree",
    filterSearch: true,
    onFilter: (value, record: DataRow) => record.permission === String(value),
    sorter: (a, b) => a.permission.localeCompare(b.permission),
  },
    {
      title: "Resource type",
      dataIndex: "resourceType",
      key: 'resourceType',
      filters: uniqueResourceType.map((type => ({
        text: type,
        value: type,
      }))),
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record: DataRow) => record.resourceType === String(value),
      sorter: (a, b) => a.resourceType.localeCompare(b.resourceType),
      render: (value, record) => <Link to={`/app/full-admin/resource?name=${record.resourceType}&id=${record.resourceId}`}>
        <Button type={"link"}>
          {value}
        </Button>
      </Link>,
    }, {
      title: "Resource id",
      dataIndex: "resourceId",
      key: 'resourceId',
      filters: uniqueResourceId.map((id => ({
        text: id,
        value: id,
      }))),
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record: DataRow) => record.resourceId === String(value),
      sorter: (a, b) => a.resourceId.localeCompare(b.resourceId),
      render: (value, record) => <Link to={`/app/full-admin/resource?name=${record.resourceType}&id=${record.resourceId}`}>
        <Button type={"link"}>
          {value}
        </Button>
      </Link>,
    },
  ]

  const dataSource = useMemo(() => permissions?.map((elem) => ({
    permission: elem.permission,
    resourceType: elem.objectReference.type,
    resourceId: elem.objectReference.id,
    key: `${elem.permission}-${elem.objectReference.type}-${elem.objectReference.id}`,
  }) ?? []), [permissions])

  return <Table size={"small"}
                dataSource={dataSource}
                columns={columns}
                pagination={{
                  defaultPageSize: 15,
                }}/>;
}


export default UserPermissions