import React, {FC, useEffect} from "react"
import useCustomSearchParams from "hooks/useCustomSearchParams"
import {useSelector} from "react-redux"
import {getResourceIds, getTypes} from "redux/selectors/definition"
import {Divider, Select, Typography} from "antd"
import useDispatch from "hooks/useDispatch"
import RelationshipList from "components/RelationshipList"
import Permissions from "components/Permissions"
import RelationshipCreator from "components/RelationshipCreator"
import {ResourceToDefinition} from "models/Definition"

const ResourceBridge: FC = () => {
  const [searchParams, setSearchParams] = useCustomSearchParams()
  const dispatch = useDispatch()

  const resourceToDefinition: ResourceToDefinition | undefined = useSelector(getTypes)
  const ids = useSelector(getResourceIds)

  useEffect(() => {
    if (searchParams?.name) {
      dispatch.definition.loadResourceIds(searchParams.name)
    }
  }, [dispatch.definition, searchParams.name])

  useEffect(() => {
    if (searchParams?.id) {
      dispatch.definition.loadObjectReferenceDefinition({type: searchParams.name, id: searchParams?.id})
    }
  }, [dispatch.definition, searchParams.name, searchParams?.id])

  return <div>
    <Typography.Title level={1}>{searchParams.name} {searchParams?.id ? `: ${searchParams?.id}` : ""}</Typography.Title>
    <Select showSearch
            allowClear
            value={searchParams?.id}
            onClear={() => setSearchParams({
              name: searchParams.name,
              id: "",
            })}
            style={{width: 300}}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={ids?.map(id => ({label: id, value: id})) ?? []}
            onSelect={(elem) => setSearchParams({name: searchParams.name, id: elem})}
    >
    </Select>
    <Divider>Relationships</Divider>
    <RelationshipCreator currentResourceType={searchParams.name} currentResourceId={searchParams?.id}
                         resources={Object.keys(resourceToDefinition)}
                         relations={[...new Set(resourceToDefinition[searchParams.name]?.relations ?? [])]}/>
    <br/>
    {searchParams?.id && <div>
      <RelationshipList resource={{type: searchParams.name, id: searchParams?.id}}/>
      <Divider>Permissions</Divider>
      <Permissions/>
    </div>}
  </div>
}


export default ResourceBridge