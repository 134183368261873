import {createModel} from "@rematch/core"
import RootModel from "redux/models"
import {EnvService, SentryService} from "services"
import {setToken} from "services/api"
import {Accesses, IamUserDtoSummary} from "models/User"
import {AuthContextProps} from "react-oidc-context"

export interface Consts {
  OIDC_ISSUER?: string
  OIDC_CLIENTID?: string
  SENTRY_URL?: string
}

interface EnvironnementState {
  consts: Consts
  authToken?: string
  auth?: AuthContextProps
  user?: IamUserDtoSummary
  accesses?: Accesses
}

const initialState: EnvironnementState = {
  consts: {},
}

export default createModel<RootModel>()({
  state: initialState,
  reducers: {
    setConsts(state, consts: Consts): EnvironnementState {
      return {
        ...state,
        consts,
      }
    },

    setUser(state, user?: IamUserDtoSummary) {
      return {
        ...state,
        user,
      }
    },
    setAccesses(state, accesses?: Accesses) {
      return {
        ...state,
        accesses,
      }
    },
    setAuthToken(state, {auth, authToken}: { auth: AuthContextProps, authToken: string }) {
      setToken(authToken)
      return {
        ...state,
        auth,
        authToken,
      }
    },
  },
  effects: (dispatch) => ({
    async loadStatic({authToken}: { authToken?: string } = {}) {

      const {consts, user, accesses} = await EnvService.getEnv()

      SentryService.init(consts.SENTRY_URL)

      if (user?.email) {
        // if (user.locale) {
        //   Language.set(user.locale)
        // }
        SentryService.configureScope(user.email)
      }

      // @ts-ignore
      global.whoami = () => {
        // eslint-disable-next-line no-console
        console.log(`${authToken}`)
        return user
      }

      // according to https://react-redux.js.org/api/batch and https://reactjs.org/blog/2022/03/29/react-v18.html#new-feature-automatic-batching, react automatically batches updates
      dispatch.environment.setConsts(consts)
      dispatch.environment.setUser(user)
      dispatch.environment.setAccesses(accesses)
    },

    async initAuth({accessToken, auth}: { accessToken: string, auth: AuthContextProps }) {
      setToken(accessToken)
      dispatch.environment.loadStatic({
        authToken: accessToken,
      })
      dispatch.environment.setAuthToken({
        auth,
        authToken: accessToken,
      })
    },

    logout({auth: defaultAuthService}: { auth?: AuthContextProps }, state) {
      const auth = defaultAuthService ?? state.environment.auth
      auth?.signoutRedirect({
          // eslint-disable-next-line camelcase
          post_logout_redirect_uri: `${window.location.origin}/`,
          // eslint-disable-next-line camelcase
          id_token_hint: auth?.user?.id_token, // prevents logout confirmation screen https://github.com/keycloak/keycloak/discussions/12183
        })
        .catch(() => SentryService.captureWarn("could not logout"))
    },
  }),
})
