import axios, {AxiosError} from 'axios'
import {notification} from "antd"
import store from "redux/store"

interface RequestConfig {
  failSilently?: boolean
  failForbiddenSilently?: boolean
}

const baseURL = process.env.REACT_APP_API_URL ?? ""

const onRejected = (error: AxiosError<any, RequestConfig>) => {
  const {response} = error
  const {status: statusCode, data} = response || {}
  if (statusCode === 401) {
    store.dispatch.environment.logout({})
    // https://github.com/axios/axios/issues/4994
  } else if (statusCode === 403 && JSON.parse(error?.config?.data as string ?? "{}").failForbiddenSilently) {
    throw error
  } else {
    notification.error({
      message: data?.title,
      description: data?.detail,
      placement: 'bottomRight',
      duration: 4,
    })
    throw error
  }
}

const Api = axios.create({baseURL})
Api.interceptors.response.use((response: any) => response.data, onRejected)

export const setToken = (accessToken: string) => {
  Api.defaults.headers.common.Authorization = `Bearer ${accessToken}`
}

export default Api