import React, {FC, useEffect} from "react"
import useCustomSearchParams from "hooks/useCustomSearchParams"
import useDispatch from "hooks/useDispatch"
import UserPermissions from "components/UserPermissions"
import UserInfo from "components/UserInfo"
import {useSelector} from "react-redux"
import {getLoading} from "redux/selectors/definition"
import {Spin} from "antd"

const UserBridge: FC = () => {
  const [searchParams, setSearchParams] = useCustomSearchParams()
  const dispatch = useDispatch()

  const loading = useSelector(getLoading)

  useEffect(() => {
    if (searchParams?.id) {
      dispatch.definition.loadSubjectPermissions({subjectId: searchParams.id, subjectType: "user"})
    }
  }, [dispatch.definition, searchParams?.id])

  return <div>
    <UserInfo userId={searchParams?.id}/>
    <br/>
    <br/>
    {loading ? <Spin/> : <UserPermissions/>}
  </div>
}


export default UserBridge